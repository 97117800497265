/**
 * Event Names
 * @type {Readonly<{deleteSelectedItems: string, selectedNotification: string, saveDetails: string, openSelectedItem: string, dialogCancel: string, dialogOK: string, selectedOption: string, click: string, alertVisibilityChanged: string, remove: string, listItemGroupChange: string, deleteDetails: string, printSelectedItems: string, closed: string, configureDataTable: string, close: string, resetDetails: string, activated: string}>}
 */
const eventNames = Object.freeze({
  resetDetails: "resetDetails",
  deleteDetails: "deleteDetails",
  saveDetails: "saveDetails",
  deleteSelectedItems: "deleteSelectedItems",
  printSelectedItems: "printSelectedItems",
  configureDataTable: "configureDataTable",
  openSelectedItem: "open-selected-item",
  dialogCancel: "cancel",
  dialogOK: "ok",
  closed: "closed",
  close: "close",
  opened: "opened",
  selectedNotification: "selectedNotification",
  selectedOption: "selectedOption",
  alertVisibilityChanged: "alertVisibilityChanged",
  activated: "activated",
  expanded: "expanded",
  remove: "remove",
  click: "click",
  listItemGroupChange: "listItemGroupChange",
  moreText: "moreText",
  removeField: "removeField",
  databaseLookup: "databaseLookup",
  categoryChanged: "categoryChanged",
  updateRecord: "update-record",
  agendaTemplateChanged: "agendaTemplateChanged",
  AgendaItemTemplateChanged: "AgendaItemTemplateChanged",
  AgendaSectionTemplateChanged: "AgendaSectionTemplateChanged",
  selectedExtension: "selected-extension",
  insertFile: "insert-file",
  customPage: "custom-page",
  overwriteFile: "overwrite-option",
  expandedPreview: "expandedPreview",
  agendaPacketOptions: "agendaPacketOptions",
  onRecordMoved: "onRecordMoved",
  onFileDropped: "onFileDropped",
  performContextMenuAction: "performContextMenuAction",
  onContextMenuAction: "onContextMenuAction",
  checkInOptionChanged: "checkInOptionChanged",
  uploadFileChanged: "uploadFileChanged",
  ocrTextSaved: "ocrTextSaved",
  itemSelected: "itemSelected",
  optionChanged: "optionChanged",
  downloadReport: "download-report",
  taskValidation: "task-validation",
  onEventClicked: "onEventClicked",
  onContextAction: "onContextAction",
  calendarContextAction: "calendarContextAction",
  onEventRightClicked: "onEventRightClicked",
  onFileChanged: "onFileChanged",
  onSearchAction: "onSearchAction",
  onSearchChipRemoved: "onSearchChipRemoved",
  openDefaultPdfViewer: "openDefaultPdfViewer",
  onCheckout: "onCheckout",
  onDialogOpened: "onDialogOpened",
  onDialogClosedAfterAction: "onDialogClosedAfterAction",
  onOk: "onOk"
});

export { eventNames };
