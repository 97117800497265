<template>
  <v-expansion-panels
    v-bind="$attrs"
    v-on="$listeners"
    :inset="inset"
    focusable
    multiple
    v-model="panels"
  >
    <expansion-panel-layout :icon-name="iconName">
      <!-- Enable Custom header -->
      <template v-slot:header v-if="hasSlot('header')">
        <slot name="header"></slot>
      </template>

      <!-- default header -->
      <template v-slot:default v-else>
        <slot name="default"></slot>
      </template>

      <template v-slot:content>
        <slot name="content"> </slot>
      </template>
    </expansion-panel-layout>
  </v-expansion-panels>
</template>

<script>
// mixins
import { slottableMixin } from "@/mixins/shared/slottable/slottableMixin";
import { eventNames } from "@/model/common/events/eventConst";

export default {
  name: "ExpansionPanelsLayout",
  inheritAttrs: false,
  mixins: [slottableMixin],
  components: {
    ExpansionPanelLayout: () =>
      import("@/components/shared/core/expansionPanel/ExpansionPanelLayout")
  },
  data() {
    return {
      panels: []
    };
  },
  props: {
    iconName: {
      type: String,
      default: undefined
    },
    open: {
      type: Boolean,
      default: false
    },
    inset: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if (this.open) {
      this.panels = [0];
    }
  },
  computed: {
    /**
     * is expanded panel
     * @return {boolean} true if panel is expanded
     */
    expanded() {
      return (this.panels?.length ?? 0) > 0 && this.panels[0] === 0;
    }
  },
  watch: {
    panels() {
      this.$emit(eventNames.expanded, this.expanded);
    },
    open(newValue) {
      this.panels = newValue ? [0] : [];
    }
  }
};
</script>
